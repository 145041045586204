<template>
	<Dialog :dialog.sync="importDialog">
		<template v-slot:title> Import {{ title }} </template>
		<template v-slot:body>
			<v-row>
				<v-col md="12">
					<p class="btx-p">
						Download a <a href="#" v-on:click="download_sample()">sample file</a> and compare it to your
						import file to ensure you have the file perfect for the import.
					</p>
				</v-col>
				<v-col md="4">
					<label for="xls-csv-file" class="required btx-label">Upload file</label>
				</v-col>
				<v-col md="8">
					<v-file-input
						:disabled="loading"
						:loading="loading"
						id="xls-csv-file"
						v-model="file"
						class="mt-0 pt-0"
						placeholder="Choose File"
						prepend-icon="mdi-paperclip"
					>
						<template v-slot:selection="{ text }">
							<v-chip small label color="primary">
								{{ text }}
							</v-chip>
						</template>
					</v-file-input>
					<p class="btx-small-p">Maximum File Size: 5 MB | File Format: CSV or XLSX</p>
				</v-col>
				<v-col md="4">
					<label for="duplicate-handling" class="required btx-label">Duplicate Handling</label>
				</v-col>
				<v-col md="8">
					<v-radio-group
						:disabled="loading"
						:loading="loading"
						id="duplicate-handling"
						class="mt-0 pt-0"
						v-model="duplicate_handling"
						column
					>
						<v-radio :disabled="loading" :loading="loading" label="Skip" value="skip"></v-radio>

						<p class="btx-p">
							Retains the records in {{ $APPNAME }} and does not sync the identical records from the import
							file.
						</p>
						<v-radio :disabled="loading" :loading="loading" label="Overwrite" value="overwrite"></v-radio>
						<p class="btx-p">
							Overwrites existing records in {{ $APPNAME }} with the identical records in the import file.
						</p>
					</v-radio-group>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				:disabled="loading"
				:loading="loading"
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				v-on:click="importDocuments"
			>
				Import
			</v-btn>
			<v-btn :disabled="loading" depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service"; /* 
import { GET_LISTING } from "@/core/services/store/listing.module"; */

export default {
	name: "import-dialog",

	data() {
		return {
			pageTitle: "Assets",
			duplicate_handling: "skip",
			file: null,
			loading: false,
		};
	},
	props: {
		importDialog: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		importSample: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
	},
	methods: {
		download_sample() {
			const url = `${process.env.VUE_APP_BASE_URL}import-sample/${this.importSample}`;
			window.open(url, "_blank");
		},
		/* getListing() {
			this.pageLoading = true;

			const relational = new Object({});

			if (this.internal) {
				relational["internal"] = 1;
				relational["type"] = this.relation_type;
				relational["type-id"] = this.relation_type_id;
			}

			const filter = new Object({
				status: "all",
				...this.$route.query,
				...this.defaultFilter,
				...relational,
				tz: new Date().getTime(),
			});
			this.$store
				.dispatch(GET_LISTING, { url: "asset", filter })
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.clearSelection();
					this.pageLoading = false;
				});
		}, */

		importDocuments() {
			ApiService.setHeader();
			const formData = new FormData();
			formData.append("excel_file", this.file, this.file.name);
			formData.append("data_type", this.duplicate_handling);
			this.loading = true;
			ApiService.upload(this.endpoint, formData)
				.then(() => {
					this.$emit("close", true);
					this.$emit("reload:content", true);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	components: {
		Dialog,
	},
};
</script>
