<template>
	<v-chip :class="customClass" :color="color" :text-color="textColor" :small="small" label>
		{{ text }}
	</v-chip>
</template>

<script>
export default {
	name: "btx-chip",
	props: {
		color: {
			type: String,
			default: "cyan",
		},
		textColor: {
			type: String,
			default: "white",
		},
		text: {
			type: String,
			default: null,
		},
		customClass: {
			type: String,
			default: null,
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
