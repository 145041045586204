<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				<template v-if="typeText">
					{{ typeText }}
				</template>
				<template v-else> Files </template>
			</v-flex>
			<v-flex class="text-right">
				<v-btn
					v-on:click="attachDialog = true"
					color="blue darken-4 text-white"
					class="ml-2"
					depressed
					tile
					><v-icon small left>mdi-playlist-plus</v-icon>Attach File</v-btn
				>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 345px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-blue-bg" width="50"></th>
						<th class="p-2 light-blue-bg">Name</th>
						<th class="p-2 light-blue-bg" colspan="2">Description</th>
					</tr>
				</thead>
				<tbody v-if="dbFiles.length">
					<tr v-for="(row, index) in dbFiles" :key="index">
						<td class="p-2 border-top-light-grey" width="50">
							<ImageTemplate style="max-width: 50px; width: 50px"></ImageTemplate>
						</td>
						<td class="p-2 border-top-light-grey">
							<p class="m-0 blue--text font-level-1">{{ row.name }}</p>
							<span class="text-muted font-small">{{ row.added_at }} by {{ row.created_by }}</span>
						</td>
						<td class="p-2 border-top-light-grey font-level-1" width="50%">
							<v-layout>
								<v-flex md11>
									<template v-if="row && row.description && row.description != 'null'">
										{{ row.description }}
									</template>
									<template v-else> No Description </template>
								</v-flex>
								<v-flex md1> {{ getFileSize(row.size) }} KB </v-flex>
							</v-layout>
						</td>
						<td class="p-2 border-top-light-grey font-level-1" width="100">
							<v-btn
								v-on:click="doAction(row, 'download')"
								small
								icon
								depressed
								color="blue darken-4"
								class="mr-2"
								><v-icon small>mdi-download</v-icon></v-btn
							>
							<v-btn small v-on:click="doAction(row, 'delete')" icon depressed color="red lighten-1"
								><v-icon small>mdi-delete</v-icon></v-btn
							>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="4">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no file at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog="attachDialog" :dialog-width="600">
			<template v-slot:title> Attach Files</template>
			<template v-slot:body>
				<v-row class="attach-dialog">
					<v-col md="12">
						<p class="font-level-1">Select Files to Upload</p>
						<div class="border-light-grey text-center py-5">
							<v-icon x-large>mdi-cloud-upload-outline</v-icon>
							<br />
							<br />
							<v-btn
								tile
								:disabled="attachLoading"
								class="border-light-grey"
								v-on:click="selectFile()"
								text
								color="blue darken-4"
								>Choose File</v-btn
							>
							<v-file-input
								v-on:change="updateFiles($event)"
								class="d-none"
								ref="upload-file"
								multiple
							></v-file-input>
							<br />
							<br />
							<span>Maximum File Size: {{ maxFileSize() }} MB</span>
							<br />
							<br />
							<template v-for="(row, index) in files">
								<v-tooltip :key="`tooltip-file-${index}`" top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-chip
											:disabled="attachLoading"
											small
											close
											v-on:click:close="removeFile(index)"
											v-bind="attrs"
											v-on="on"
											class="mx-1 mb-2"
											:key="`chip-file-${index}`"
											color="blue darken-4 white--text"
											>{{ row.name }}</v-chip
										>
									</template>
									<span> {{ getFileSize(row.size) }} KB </span>
								</v-tooltip>
							</template>
						</div>
						<label for="file-description" class="btx-label mt-2">Description</label>
						<TextAreaInput
							:rows="4"
							dense
							id="file-description"
							ref="description"
							hide-details
							v-model="description"
							placeholder="Enter Description..."
							:disabled="attachLoading"
							:loading="attachLoading"
						></TextAreaInput>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="attachLoading" v-on:click="attachDialog = false">
					Cancel
				</v-btn>
				<v-btn
					class="white--text"
					:loading="attachLoading"
					:disabled="attachLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="attachFile()"
				>
					Upload
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="deleteDialog" :dialog-width="600">
			<template v-slot:title> Delete File</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">Deleting File is irreversible, Are you sure about deleting it?</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteLoading"
					:disabled="deleteLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteFile()"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { toNumber, round } from "lodash";
import ImageTemplate from "@/view/components/Image";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import ApiService from "@/core/services/api.service";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		reload: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		reload(param) {
			if (param) {
				this.getFiles();
			}
		},
	},
	data() {
		return {
			pageLoading: true,
			deleteLoading: false,
			deleteDialog: false,
			attachDialog: false,
			attachLoading: false,
			uuid: null,
			description: null,
			files: [],
			dbFiles: [],
		};
	},
	methods: {
		init() {
			this.deleteLoading = false;
			this.deleteDialog = false;
			this.attachDialog = false;
			this.attachLoading = false;
			this.uuid = null;
			this.description = null;
			this.files = [];
		},
		updateFiles(param) {
			if (param) {
				const max_size = toNumber(this.$uploadSizeLIMIT);
				const max_file = toNumber(this.$uploadLIMIT);
				for (let i = 0; i < param.length; i++) {
					const size = round(toNumber(param[i].size / 1024), 2);
					if (size <= max_size && this.files.length < max_file) {
						this.files.push(param[i]);
					}
				}
			}
		},
		getFileSize(size) {
			return round(toNumber(size) / 1024, 2);
		},
		removeFile(index) {
			this.files.splice(index, 1);
			this.$refs["upload-file"].value = null;
		},
		maxFileSize() {
			return round(toNumber(this.$uploadSizeLIMIT) / 1024, 2);
		},
		selectFile() {
			this.$refs["upload-file"].reset();
			this.$refs["upload-file"].$refs["input"].value = null;
			this.$nextTick(() => {
				this.$refs["upload-file"].$refs["input"].click();
			});
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.uuid = row.uuid;
					this.deleteDialog = true;
					break;
			}
		},
		deleteFile() {
			if (!this.uuid) {
				return false;
			}
			this.deleteLoading = true;
			ApiService.setHeader();
			ApiService.delete(`${this.type}/${this.typeUuid}/file/${this.uuid}`)
				.then(() => {
					this.getFiles();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.deleteLoading = false;
				});
		},
		getFiles() {
			ApiService.setHeader();
			ApiService.get(`${this.type}/${this.typeUuid}/file`)
				.then(({ data }) => {
					this.dbFiles = data;
					this.init();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		attachFile() {
			const params = new FormData();

			for (let i = 0; i < this.files.length; i++) {
				params.append(`file[${i}]`, this.files[i]);
			}

			params.append("description", this.description);

			this.attachLoading = true;

			ApiService.setHeader();
			ApiService.upload(`${this.type}/${this.typeUuid}/file`, params)
				.then(() => {
					this.getFiles();
					this.$refs["upload-file"].value = null;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.attachLoading = false;
				});
		},
	},
	components: {
		Dialog,
		TextAreaInput,
		ImageTemplate,
	},
	mounted() {
		this.getFiles();
		EventBus.$on("reload:asset", () => {
			this.getFiles();
		});
	},
};
</script>
