<template>
	<v-chip
		:class="customClass"
		:color="statusObject ? statusObject.color : ''"
		:text-color="statusObject ? statusObject.textcolor : ''"
		label
	>
		{{ statusObject ? statusObject.text : "" }}
	</v-chip>
</template>

<script>
import { mapGetters } from "vuex";
import { find } from "lodash";

export default {
	name: "btx-chip",
	props: {
		status: {
			type: String,
			default: null,
		},
		customClass: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters(["listingStatus"]),
		statusObject() {
			return find(this.listingStatus, { value: this.status });
		},
	},
};
</script>
